import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import ReactGA from "react-ga";

import Header from "./Header";
import Timeline from "./Timeline";
import TypeingText from "./typingText";
import Footer from "./footer";
import Arrow from "./arrow";
import Updates from "./updates";

import { Helmet } from "react-helmet";

const logo_url =
  "https://resumearash.blob.core.windows.net/images/profile-pic-trans.png?sp=r&st=2021-07-19T15:10:03Z&se=2023-01-02T00:10:03Z&spr=https&sv=2020-08-04&sr=b&sig=Vn64A709kcpbI8%2FImJGy%2BqFsC6GXd6t5bDpWiSGAwXI%3D";

// markup
const IndexPage = () => {
  useEffect(() => {
    document.title = "Arash Abdollahzadeh";
  }, []);

  useEffect(() => {
    ReactGA.initialize("UA-202816673-1");

    ReactGA.pageview("/");
  }, []);
  return (
    <>
      <Helmet>
        <title>Arash Abdollahzadeh</title>
        <meta property="og:image" content={logo_url} />
        <link rel="canonical" href="https://arsaha.dev" />
      </Helmet>
      <Header> </Header>
      <Container fluid>
        <Row>
          <TypeingText key="idtext"> </TypeingText>
        </Row>
        <Row>
          <Col sm="7" md="6" lg="7">
            <Timeline> </Timeline>
          </Col>
          <Col sm="6" md="6" lg="5">
            <Updates></Updates>
          </Col>
        </Row>
        <Row>
          <Footer> </Footer>
        </Row>
        <Row>
          <Arrow></Arrow>
        </Row>
      </Container>
    </>
  );
};

export default IndexPage;
